import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { ClipLoader } from 'react-spinners';

import walletExitButton from './../assets/img/walletButton.svg'

export default function ConnectButton() {
    const [loading, setLoading] = useState(false);
    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const { disconnect } = useDisconnect();
    const label = isConnected ? address : "Connect";

    async function onOpen() {
        setLoading(true);
        await open();
        setLoading(false);
    }

    function onClick() {
        if (isConnected) {
            disconnect();
        } else {
            onOpen();
        }
    }

    return (
        <>
            <button className={`bg-[#E27714] rounded-[10px] w-[120px] text-ellipsis overflow-hidden whitespace-nowrap px-[15px] py-[10px] text-white ${isConnected ? "hidden" : "block"}`} onClick={onClick} disabled={loading}>
                {loading ? (<ClipLoader size={18} color="#FFFFFF"></ClipLoader>) : "Connect"}
            </button>
            <div className={`flex ${isConnected ? "block" : "hidden"}`}>
                <div className="text-white text-[17px] font-bold w-[120px] whitespace-nowrap overflow-hidden text-ellipsis" >{address}</div>
                <button onClick={onClick}>
                    <img src={walletExitButton}></img>
                </button>
            </div>
        </>

    );
}