import biglogo from "./../assets/img/biglogo.svg";
import { useState } from "react";
import { useWeb3Modal } from '@web3modal/react'
import ConnectButton from "./connectButton";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-white transition ease transform duration-300`;
    const { open, close } = useWeb3Modal()

    return (<header className=" h-24 px-[20px] lg:px-[59px] py-[23px] flex flex-row items-center justify-between border-b-[1px] border-[#3F3F3F] absolute top-0 w-full">
        <div className="logo w-[120px] lg:w-[200px]">
            <img src={biglogo} />
        </div>
        <div className="flex items-center relative">
            <div className={`navbar-menu py-5 px-2 space-y-5 absolute flex-col top-16 right-2 bg-[#131313] border-[1px] border-[#242424] lg:border-none lg:relative lg:flex-row lg:space-x-5 lg:space-y-0 lg:top-0 lg:right-0 lg:bg-transparent lg:py-0 lg:px-2 ${isOpen ? "flex" : "hidden"} lg:flex`}>
                <div className="text-white text-[17px] font-bold hover:text-[#E27714] px-5 cursor-pointer">
                    Home
                </div>
                <div className="text-white text-[17px] font-bold hover:text-[#E27714] px-5 cursor-pointer">
                    Roadmap
                </div>
                <div className="text-white text-[17px] font-bold hover:text-[#E27714] px-5 cursor-pointer">
                    Twitter
                </div>
                <div className="text-white text-[17px] font-bold hover:text-[#E27714] px-5 cursor-pointer">
                    Tokenomics
                </div>
                <div className="text-white text-[17px] font-bold hover:text-[#E27714] px-5 cursor-pointer">
                    Contract
                </div>
            </div>
            <button
                className="flex-col h-12 w-12 rounded justify-center items-center group hidden"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div
                    className={`${genericHamburgerLine} ${isOpen
                        ? "rotate-45 translate-y-3 opacity-100 group-hover:opacity-90"
                        : "opacity-100 group-hover:opacity-90"
                        }`}
                />
                <div
                    className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-100 group-hover:opacity-90"
                        }`}
                />
                <div
                    className={`${genericHamburgerLine} ${isOpen
                        ? "-rotate-45 -translate-y-3 opacity-100 group-hover:opacity-90"
                        : "opacity-100 group-hover:opacity-90"
                        }`}
                />
            </button>
        </div>
        <ConnectButton></ConnectButton>
    </header>);
}
export default Navbar;