import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'
import { getDocs, collection, getDoc, } from 'firebase/firestore';
import { ref, getDownloadURL } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import axios from 'axios';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBjXm4llyFEneS4LASgOwzrOO7QFGEn064",
    authDomain: "dust-51e6b.firebaseapp.com",
    databaseURL: "https://dust-51e6b-default-rtdb.firebaseio.com",
    projectId: "dust-51e6b",
    storageBucket: "dust-51e6b.appspot.com",
    messagingSenderId: "397046790172",
    appId: "1:397046790172:web:77195110b2dabf7a4a87d9",
    measurementId: "G-7YVRVQNCRM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);


export async function getTokenData(doc) {
    const tokenInfo = doc.data();
    const tokenDatainfo = await axios.get(`https://api.dev.dex.guru/v1/chain/1/tokens/${tokenInfo.ca}/market?api-key=S5a8FMI9fWx7A9zOFFQV0_6qg7GcSg3ghAj_TWISkoc`);
    return {
        tokenSB: doc.id,
        tokenCA: tokenInfo.ca,
        tokenDC: tokenInfo.decimals,
        tokenData: tokenDatainfo.data
    }
};
