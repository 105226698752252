import logo from "./../assets/img/logo.svg";

import smalllogo from "./../assets/img/small logo.svg"
import swap_btn from './../assets/img/swapButton.svg';
import "./../App.css";

import Navbar from "./../components/navbar";

import { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { isAddress } from 'web3-validator';
import React from "react";

import { db, functions, getTokenData } from '../firebase/firebase';
import { getDocs, collection, getDoc, } from 'firebase/firestore';
import { httpsCallable } from "firebase/functions";
import Web3 from "web3";

import { ethers } from "ethers";

import { ClipLoader } from 'react-spinners';
import { AiFillCloseCircle } from 'react-icons/ai'

import axios from 'axios';
import enableDust from './../assets/img/DUST-ena.svg';
import disableDust from './../assets/img/DUST-dis.svg';

const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/d423c2bbfb1f4d3cb1275e3ed5df2045'));

let minABI = [
    // balanceOf
    {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "balance", "type": "uint256" }],
        "type": "function"
    },
    // decimals
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [{ "name": "", "type": "uint8" }],
        "type": "function"
    }
];

const chains = {
    ethereum: 1,
    binance: 56,
    polygon: 137
};


function SwapPage() {
    const { isConnected, address } = useAccount();
    const [walletAddr, setWalletAddr] = useState("");
    const [isAvailableValudation, setIsAvailableValudation] = useState(false);
    const [tokenLists, setTokenLists] = useState([]);
    const [totalUSDAmount, setTotalUSDAmount] = useState(0);
    const [isCalcing, setIsCalcing] = useState(false);
    const [valuationMode, setValuationMode] = useState(0);
    const [tokenDetailContent, setTokenDetailContent] = useState([]);
    const [limitAmount, setLimitAmount] = useState(0);



    function onHandleAddrChange(event) {
        setWalletAddr(event.target.value);
        if (isAddress(event.target.value) == true)
            setIsAvailableValudation(true);
        else
            setIsAvailableValudation(false);
    }

    useEffect(() => {
    }, []);

    const getValuation = async () => {
        if (isAvailableValudation == true) {
            // if (address == walletAddr) {
            setIsCalcing(true);
            var totalAmount = 0;
            let tokenDetailContent_tmp = [];
            var amountList = [];

            const getBalance = httpsCallable(functions, 'balance');
            const res = await getBalance({ address: walletAddr, limitAmount: limitAmount });
            console.log(res.data);
            for (let chain of Object.keys(chains)) {
                console.log("chain", res.data[chain]);
                for (const token of res.data[chain]) {
                    console.log("token:", token);

                    totalAmount += token.amount;
                    tokenDetailContent_tmp = [...tokenDetailContent_tmp, (
                        <div className="flex justify-between w-full text-[#CBCBCB] py-[3px]">
                            <div className="">{token.token.contract_ticker_symbol}</div>
                            <div className="">${token.amount.toFixed(2)}</div>
                        </div>
                    )];
                }
            }

            setTokenDetailContent(tokenDetailContent_tmp);
            setTotalUSDAmount(totalAmount.toFixed(2));

            setIsCalcing(false);
        }
        else {
            setIsCalcing(true);
            alert("Wallet address is invalid");
            setIsCalcing(false);
        }
    }

    return (
        <div className="jura bg-[#0E0E0E] w-full h-[100vh] relative">
            <Navbar></Navbar>
            <section className="w-full flex flex-col items-center pt-[120px] lg:pt-40 px-3 bg-[#0E0E0E]">
                <div className="swap-caption">
                    <div className="text-white text-[32px] lg:text-[60px] text-center flex flex-wrap justify-center">Swap your dust&nbsp;<div>for D-UST</div></div>
                    <span className="inter text-[19px] flex-wrap justify-center mt-8 hidden lg:flex">
                        <div className="text-white">Clean up your wallet and swap your dust for</div> &nbsp;
                        <div className="text-white font-bold">D-UST.</div>&nbsp;
                        <div className="text-[#E27714]">Millions of dollars have just been unlocked.</div>
                    </span>
                </div>
                <div className="inter swap-platform border-[1px] border-[#242424] bg-[#131313] max-w-[560px] w-full rounded-[25px] px-4 mt-4 lg:mt-14 pb-5">
                    <div className="text-white text-[17px] font-medium px-3 py-6 flex justify-between">
                        <div className="flex items-center space-x-6">
                            <div className={`${valuationMode == 0 ? 'opacity-100' : 'opacity-30'} cursor-pointer`} onClick={() => setValuationMode(0)}>Valuation</div>
                            <div className={`${valuationMode == 1 ? 'opacity-100' : 'opacity-30'} cursor-pointer`} onClick={() => setValuationMode(1)}>Details</div>
                        </div>
                        <img src={smalllogo}></img>
                    </div>
                    <div className={`${valuationMode == 0 ? 'block' : 'hidden'}`}>
                        <div className="bg-[#1B1B1B] rounded-[15px] w-full py-3 px-6">
                            <div className="text-[#9B9B9B] text-[15px] font-medium">Wallet address</div>
                            <div className="flex items-center">
                                <input type="text" className="text-[#D9D9D9] text-[17px] border-none outline-none bg-transparent py-6 w-full" placeholder="Paste your public wallet address to value your dust" value={walletAddr} onChange={onHandleAddrChange}></input>
                                <AiFillCloseCircle className={`text-[#827E7E] ${isAvailableValudation ? 'block' : 'hidden'} cursor-pointer`} onClick={() => { setWalletAddr(""); setIsAvailableValudation(false); setTotalUSDAmount(0); setTokenLists([]); setTokenDetailContent([]) }}></AiFillCloseCircle>
                            </div>
                        </div>
                        <div className="w-full h-[10px] relative">
                            <div className="w-full absolute flex flex-row justify-center">
                                <button className="-mt-[18px]"><img src={swap_btn}></img></button>
                            </div>
                        </div>
                        <div className="bg-[#1B1B1B] rounded-[15px] w-full py-3 px-6">
                            <div className="text-[#9B9B9B] text-[15px] font-medium">Your Price</div>
                            <div className={`w-full flex flex-row justify-between items-center `}>
                                <input type="text" className={`text-[#D9D9D9] text-[40px] border-none outline-none bg-transparent py-2 w-full  ${totalUSDAmount == 0 ? "opacity-50" : "opacity-100"}`} value={totalUSDAmount} readOnly="readonly"></input>
                                <div className={`Conthrax text-[32px] font-bold bg-transparent `}>{totalUSDAmount == 0 ? (<img src={disableDust} />) : (<img src={enableDust} />)}</div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between">
                            <div className="text-[#9B9B9B] text-[15px] font-medium py-4">Dust value limit :</div>
                            <div className="flex py-3 space-x-2">
                                <div className={`bg-[#343434] rounded-[5px] text-[15px] flex items-center px-[13px] ${limitAmount == 20 ? ("bg-[#E27714]") : ("text-[#9B9B9B]")} hover:text-white`} onClick={() => setLimitAmount(20)}>$20</div>
                                <div className={`bg-[#343434] rounded-[5px] text-[15px] flex items-center px-[13px] ${limitAmount == 50 ? ("bg-[#E27714]") : ("text-[#9B9B9B]")} hover:text-white`} onClick={() => setLimitAmount(50)}>$50</div>
                                <div className={`bg-[#343434] rounded-[5px] text-[15px] flex items-center px-[13px] ${limitAmount == 100 ? ("bg-[#E27714]") : ("text-[#9B9B9B]")} hover:text-white`} onClick={() => setLimitAmount(100)}>$100</div>
                            </div>
                        </div>
                        <div className="w-full">
                            <button className={`w-full text-white py-6 rounded-[15px] cursor-pointer ${isAvailableValudation ? "bg-[#E27714] hover:bg-[#995415]" : "bg-[#494949] opacity-50"}`} onClick={() => getValuation()}>{isCalcing ? (<ClipLoader size={18} color="#FFFFFF"></ClipLoader>) : ("Get Valuation")}</button>
                        </div>
                    </div>
                    <div className={`${valuationMode == 1 ? 'block' : 'hidden'}`}>
                        <div className="bg-[#1B1B1B] rounded-[15px] w-full px-6 h-full py-3">
                            <div className="inter text-[#9B9B9B] text-[15px] font-medium py-3">Dust Tokens</div>
                            <div className="overflow-x-hidden overflow-y-auto h-[278px] flex flex-col space-y-2 pr-3">
                                {tokenDetailContent}
                            </div>
                            <div className="flex justify-between w-full text-[#CBCBCB] py-[3px] pr-4">
                                <div className="w-20 overflow-hidden whitespace-nowrap text-ellipsis">Total</div>
                                <div className="">${totalUSDAmount}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-white text-[15px] py-8 text-center">{`Current Valuation “1.000.000” (take value from settings in Firebase)`}</div>
            </section>
        </div>
    );
}

export default SwapPage;
